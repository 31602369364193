import React from 'react';
import {
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Brain from '../../assets/images/icons/brain-thought.svg';
import Label from '../../assets/images/icons/label.svg';
import Reward from '../../assets/images/icons/reward.svg';
import Shield from '../../assets/images/icons/shield.svg';
import Touch from '../../assets/images/icons/touch.svg';
import Website from '../../assets/images/icons/website.svg';

const Lyceum = () => {
  return (
    <PageLayout>
      <SEO
        title="White-label Your Own Blockchain Based Education Marketplace"
        description="Learning Managament System providing a training framework incentivizing people to develop skills based on their needs
        and level of experience."
      />
      <Layout background="blue">
        <Hero type="code">
          <div className="caption">Lyceum</div>
          <Title color="white">White-label Your Own Blockchain Based Education Marketplace</Title>
          <Description color="blue-light">
            Lyceum provides a training framework incentivizing people to develop skills based on their needs
            and level of experience.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Brain,
                title: 'Proof of Knowledge',
                body: 'Publish & track certifications on the blockchain.',
              },
              {
                Icon: Reward,
                title: 'Reward System',
                body: 'Earn while you learn with  tokenized rewards.',
              },
              {
                Icon: Shield,
                title: 'Impossible to Falsify',
                body: 'Proof of Corporate Training.',
              },
              {
                Icon: Website,
                title: 'Customize',
                body: 'Create customizable lesson plans in any language.',
              },
              {
                Icon: Label,
                title: 'White-Label',
                body: "Fully transform to your organization's branding style",
              },
              {
                Icon: Touch,
                title: 'Onboarding and CEU',
                body: 'Continuing education units tracking & training',
              },
            ]}
          />
          {/* <Row gap="large">
            <Column span={6}>
              <Section title="See/Try it in action">Hello world.</Section>
            </Column>
          </Row> */}
          <Row>
            <Column span={7}>
              <Section title="White label solutions.">
                Incentivize behaviour in exciting new ways, to make people you care about never stop learning
                new skills. How can we get Lyceum to work for you?
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Lyceum;
